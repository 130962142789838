import React from "react";
import Card from "./UserCard";

import firstcard from "../image/firstcarduser.png";
import secondcard from "../image/secondcarduser.png";
import thirdcard from "../image/thirdcarduser.png";

import {
  Row  
} from "reactstrap";

const UserButton = () => {
  return (
    <div>
    <div className="d-flex flex-md-row flex-column justify-content-around align-items-center my-3">      
          <Card login="byDoctor" Image={firstcard} link={"/doctor/searchdoctor"} />
          <Card login="byUser" Image={secondcard} link={"/client/searchclient"}/>
          <Card login="byDate" Image={thirdcard} link={"/datemain"}/>                              
    </div>   
    <div className="d-flex flex-md-row flex-column justify-content-around align-items-center my-3">            
      <Card login="byCancel" Image={thirdcard} link={"/datemain"}/>                    
    </div>  
    </div>
  );
};

export default UserButton;
