import React from "react";
import Footer from "../Basic/Footer";
import Navbar1 from "../Basic/Navbar";
import Navbar2 from "../Basic/Navbar2";
import About from "../Home/About";
import CompanyInfo from "../Home/CompanyInfo";
import Service from "../Home/Services";
import Jumbo from "../Home/Jumbo-video";
//import LoginButton from "../Home/LoginButton";
import ContactForm from "../Home/ContactForm"; 
import Footer2 from "../Basic/Footer2";
import MainMenu from "../Basic/Menu";

const Home = () => {
  return (
    <div>
      <Navbar2 />
      <Navbar1 />
      <MainMenu />
      <Jumbo />
      <About />
      <Service />  
      <div className="contact-container">
        <div className="column-page2">
          <CompanyInfo />
        </div>  
        <div className="column-page2">
          <ContactForm />   
        </div>
      </div>        
      <Footer2 />
    </div>
  );
};

export default Home;

//{<LoginButton />}
