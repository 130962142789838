import React, { useEffect, useState } from "react";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";

import PopupMessage from "./Pages/PopupMessage";
import Home from "./Pages/Home";
import Services from "./Home/Services";
import AboutUs from "./Home/AboutUs";
import ContactForm from "./Home/ContactFormUs";
import DoctorLogin from "./Pages/DoctorLogin";
import UserLogin from "./Pages/UserLogin";
import UserMain from "./Pages/UserMain";

import mainDashboard from "./Pages/UserMain"
import TodayAppointments from "./Report/TodayAppointments";
import UserDashboard from "./Pages/UserDashboard";
import ClientDashboard from "./Pages/ClientDashboard";
import Error from "./Pages/Error";
import { AuthContext } from "./Auth/AuthContext";
import PhoneNumber from "./components/PhoneNumber";
import PersonalDetails from "./Doctor/PersonalDetails";
import SearchDoctor from "./Doctor/SearchDoctor";
import SelectDoctorDate from "./Doctor/Selectdate";

import PerviousAppointments from "./Report/PerviousAppointments";
import Spinner from "react-bootstrap/Spinner";
import Selectdate from "./User/Selectdate";
import BookingSlots from "./Doctor/BookingSlots";
import Payment from "./Client/Payment"; 

import SignUp from "./Userlogin/SignUp"; 
import AddUser from "./User/AddUser"; //2023-03-13
import NewUser from "./User/NewUser";//2023-03-13

import AddClient from "./Client/AddClient"; //2023-03-13
import NewClient from "./Client/NewClient";//2023-03-13
import SearchClient from "./Client/SearchClient";//2023-03-19

import DocAppointments from "./Report/PaymentHistory";
import AppointmentStatus from "./Report/AppointmentStatus";
import CancelAppointment from "./Pages/CancelAppointment";

import DateMain from "./Pages/DateMain";
import DateMain2 from "./Pages/DateMain2";

//import { GoogleApiProvider } from "react-gapi" 
//import Pfeedback from './Patient/Feedback';
//import FeedbackDetails from './Doctor/FeedbackDetails';

function App() {
	const [token, setToken] = useState(window.localStorage.getItem("token"));
	const [googleId, setGoogleId] = useState(
		window.localStorage.getItem("googleId")
	);

	const [apiLoaded, setApiLoaded] = useState(false);
		
	// To load only when gapi is loaded
	useEffect(() => {		
		if (window.gapi !== undefined) {			
			/*
			setApiLoaded(false);
			window.gapi.load("client:auth2", initClient);
			function initClient() {
				window.gapi.client
					.init({
						apiKey: "AIzaSyAq-Ru7CuN9xUJNN-pnCzG8Td2gRNN6L20", //process.env.REACT_APP_API_KEY,
						clientId: "985357798608-cviarluicolh6v6bhmg97a6ko1kjq3od.apps.googleusercontent.com", //process.env.REACT_APP_CLIENT_ID,
						discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"], //[process.env.REACT_APP_DISCOVERY_DOCS],
						scope: "https://www.googleapis.com/auth/calendar.events", //process.env.REACT_APP_SCOPE,
					})
					.then(
						function () {
							if (window.gapi.auth2.getAuthInstance().isSignedIn.get()) {
								console.log(
									`Is signed in? ${window.gapi.auth2
										.getAuthInstance()
										.isSignedIn.get()}`
								);
							} else {
								console.log("Currently Logged Out!!");
							}
							setApiLoaded(true);
						},
						function (error) {
							console.log(`error ${JSON.stringify(error)}`);
							setApiLoaded(true);
						}
					);
			}
			*/
			setApiLoaded(true);
		} else {
			console.log("[Google] inside the else block line 54 App.js");
			setApiLoaded(false);
		}

	}, []);

	return apiLoaded ? (
		<Router>
			<AuthContext.Provider value={{ token, setToken, googleId, setGoogleId }}>
				<Switch>
					<Route exact path="/" component={Home} />
					
					<Route exact path="/popup" component={PopupMessage} />
					<Route exact path="/aboutus" component={AboutUs} />
					<Route exact path="/contactform" component={ContactForm} />
					<Route exact path="/doctor/searchdoctor" component={SearchDoctor} />
					<Route exact path="/doctorlogin" component={DoctorLogin} />					
					<Route exact path="/todayappointments" component={TodayAppointments} />		
					<Route exact path="/doctor/selectdate" component={SelectDoctorDate} />			
					<Route exact path="/doctor/personaldetails" component={PersonalDetails}	/>
					<Route exact path="/doctor/payment-history"	component={DocAppointments} />					
					
					<Route exact path="/client" component={ClientDashboard} />
					<Route exact path="/client/searchclient" component={SearchClient} />
					<Route exact path="/client/newclient" component={NewClient} />
					<Route exact path="/client/addclient" component={AddClient} />
					<Route exact path="/client/update-phone" component={PhoneNumber} />
					<Route exact path="/client/previousappointments" component={PerviousAppointments} />
					<Route exact path="/client/book-slot" component={BookingSlots} />
					<Route exact path="/client/appointment-status" component={AppointmentStatus} />
					<Route exact path="/client/cancelappointment" component={CancelAppointment} />
					
					<Route exact path="/main" component={mainDashboard} />
					<Route exact path="/user/payment" component={Payment} />
					<Route exact path="/services" component={Services} />
					<Route exact path="/signup" component={SignUp} />
					
					<Route exact path="/user" component={UserDashboard} />					
					<Route exact path="/user/adduser" component={AddUser} />
					<Route exact path="/user/newuser" component={NewUser} />					
					<Route exact path="/userlogin" component={UserLogin} />
					<Route exact path="/usermain" component={UserMain} />
					<Route exact path="/user/selectdate" component={Selectdate} />

					<Route exact path="/datemain" component={DateMain} />
					<Route exact path="/datemain2" component={DateMain2} />
					
					<Route path="*">
						<Error />
					</Route>
				</Switch>
			</AuthContext.Provider>
		</Router>
	) : (
		<div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
			<Spinner animation="border" variant="danger" role="status">
				<span className="sr-only">Loading...</span>
			</Spinner>
		</div>
	);
}

export default App;


/*
<Route exact path="/doctor/feedback/:id" component={FeedbackDetails} />
<Route exact path="/patient/feedback/:id" component={Pfeedback} />

*/