import React, { useState, useEffect } from "react";
import axios from "axios";

const Services = () => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    const fetchServices = async () => {      
      const { data } = await axios.get(
        `http://localhost:5000/services`        
      );

      setServices(data);
      console.log(data);
    };

    fetchServices();
  }, []);

  return (    
    <div className="container">
      <h2 style={{textAlign:"center"}}>Features of CAMS</h2>
   
      <div className="card-columns">
        {services.map((serv) => (
          <div className="card bg-warning" key={serv._id}>         
            <div className="card-body text-center">
              <h5 className="card-title" style={{color:"darkolivegreen"}}>{serv.title}</h5>              
              <p className="card-text">
                {serv.name}  
              </p>
            </div>
          </div>            
        ))}
      </div>
    </div>   
  );
};

export default Services;
