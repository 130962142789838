import React, {useState} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
//import { Button } from "reactstrap";

const MainMenu = () => {
  const [isDoctor, setIsDoctor] = useState(true);
  const [isClient, setIsClient] = useState(true);
  const [isGeneral, setIsGeneral] = useState(true);

  return (
    <nav className="navbar navbar-expand-lg navbar-light pl-2 pr-10 w-100 menubar">
      <div className="container menubar">
        <div className="menubar">
          <Link to={{
            pathname: "/",
          }}>
            Home
          </Link>
        </div>
        { isGeneral && <div className="vr"></div>}
        { isGeneral &&
          <div className="dropdown menubar">
          <Link to={{}} className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
            Appointments
          </Link>
          <div className="dropdown-menu">          
            <Link to={{pathname: "/datemain",}}> By Date</Link> 
            <div className="dropdown-divider"></div>
            <Link to={{pathname: "/doctor/searchdoctor",}}> By Doctor</Link> 
            <div className="dropdown-divider"></div>
            <Link to={{pathname: "/client/searchclient",}}> By Client</Link> 
            <div className="dropdown-divider"></div>
            <Link to={{pathname: "/client/cancelappointment",}}> Cancel Appointments</Link>             
          </div>      
        </div>}
        { isClient && <div className="vr"></div>}
        { isClient && <div className="dropdown menubar">
          <Link to={{}} className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
            Clients
          </Link>
          <div className="dropdown-menu">          
            <Link to={{pathname: "/todayappointments",}}> Add Review</Link> 
            <div className="dropdown-divider"></div>
            <Link to={{pathname: "/client/previousappointments",}}> Check Appointment</Link> 
            <div className="dropdown-divider"></div>
            <Link to={{pathname: "/doctor/payment-history",}}> Cancel Appointment</Link>     
          </div>
        </div>}
        { isDoctor && <div className="vr"></div>}
        { isDoctor && <div className="dropdown menubar">
          <Link to={{}} className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
            Doctors
          </Link>
          <div className="dropdown-menu">          
            <Link to={{pathname: "/todayappointments",}}> Check Appointments</Link> 
            <div className="dropdown-divider"></div>
            <Link to={{pathname: "/client/previousappointments",}}> Cancel Appointment</Link> 
            <div className="dropdown-divider"></div>
            <Link to={{pathname: "/doctor/payment-history",}}> Set Schedule (Vacation)</Link>     
          </div>      
        </div>}
        <div className="vr"></div>
        {<div className="dropdown menubar">
          <Link to={{}} className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
            Reports
          </Link>
          <div className="dropdown-menu">          
            <Link to={{pathname: "/todayappointments",}}> Today's Schedule</Link> 
            <div className="dropdown-divider"></div>
            <Link to={{pathname: "/client/previousappointments",}}> Previous Appointments - Client</Link> 
            <div className="dropdown-divider"></div>
            <Link to={{pathname: "/doctor/payment-history",}}> Previous Appointments - Doctor</Link> 
            <div className="dropdown-divider"></div>
            <Link to={{pathname: "/client/appointment-status",}}> Appointment Status</Link> 
            <div className="dropdown-divider"></div>
            <Link to={{pathname: "/aboutus",}}> Pending Appointments</Link> 
          </div>      
        </div>}
        <div className="vr"></div>     
        {<div className="dropdown menubar">
          <Link to={{}} className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
            Management
          </Link>
          <div className="dropdown-menu">          
            <Link to={{pathname: "/todayappointments",}}> Doctor (Add, Edit & Delete)</Link> 
            <div className="dropdown-divider"></div>
            <Link to={{pathname: "/client/previousappointments",}}> Client (Add, Edit & Delete)</Link> 
            <div className="dropdown-divider"></div>
            <Link to={{pathname: "/doctor/payment-history",}}> User (Add, Edit & Delete)</Link> 
          </div>      
        </div>}
        <div className="vr"></div>     
        <div className="menubar" >
          <Link to={{
              pathname: "/aboutus",
          }}>
            About Us
          </Link>
        </div>
        <div className="vr"></div>
        <div className="menubar">
          <Link to={{
            pathname: "/contactform",
          }}>
            Contact Us
          </Link>            
        </div> 
      </div>  
    </nav>
  );
}

export default MainMenu;