import React, { useContext, useState, useEffect } from "react";
import Scrollbar from "react-scrollbars-custom";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Modal,  ModalBody } from "reactstrap";
import { Container, Card, Form, CardHeader, CardBody, FormGroup, CardFooter, Label } from 'reactstrap'
//import DateMain from '../Pages/DateMain'

import {
  Row,
  Col,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import axios from 'axios';
import { AuthContext } from '../Auth/AuthContext';

const Search_Small = () => {

  //set the selected client to blank when the page is loaded
  //localStorage.setItem("client","");

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [phoneno, setPhoneno] = useState('');
  const [sex, setSex] = useState('');
  const [comment, setComment] = useState('');
  const [status, setStatus] = useState(0);
  const [text, setText] = useState();
  const [suggestions, setSuggestions] = useState([]);
  const [modal, setModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState(localStorage.getItem("client"))

  const { token, setToken, googleId, setGoogleId } = useContext(AuthContext);
  const history = useHistory();
  const toggle = () => setModal(!modal);	 

  //console.log("token - " + token + " googleId - " + googleId);

  async function addClient(e) {
		try {
			const res = await axios.post("http://localhost:5000/clients/add/",
				{
					email: email,
	        name: name,
          address: address,
          phoneno: phoneno,
          sex: sex,
          comment: comment
				}
			);
			setStatus(res.status);

      /*
			const token = res.data.token;
			if (res.status === 200) {
				window.localStorage.setItem("token", token);

				// Remove the googleId if it exist in the local storage
				window.localStorage.removeItem("googleId");
				setGoogleId(null);
				setToken(token);
        */

				history.push('/usermain');
			//}
		} catch (err) {
			console.log(err);
		}
  }

  function storeClientId(client) {    
    localStorage.setItem("client", JSON.stringify(client));
    setSelectedClient(client);
  }

  function onTextChanged(e) {
    let value = e.target.value;
    setText(value);
    fetchClient();
    value = value.toLowerCase();
    //if (value !== "") setSuggestions(memoized_trie.find(value));
    //else setSuggestions([]);
  }

  function suggestionSelected(value) {
    setText(value);
    setSuggestions([]);
  }

  function renderSuggestions() {
    if (suggestions.length === 0) {
      return null;
    }  

    return (
      <InputGroup>
        <ul className="list-group dropdown-menu pt-0 pb-0">
          {suggestions.map((item) => (
            <li
              className="list-group-item list-group-item-action"
              onClick={() => suggestionSelected(item)}
              key={item}
            >
              {item} 
            </li>
          ))}
        </ul>
      </InputGroup>
    );
  }

  const [Client, setClient] = useState([]);

  const fetchClient = async () => {
    const { data } = await axios.get(
      `http://localhost:5000/clients/`
    );
    setClient(data);
    //console.log(data);
  };

  const UpdateDisplay = (text) => {
    setClient((Client) => {
      return Client.filter(
        (client) => client.name.toLowerCase().includes(text.toLowerCase())
      );
    });
  };

  useEffect(() => {
    fetchClient();
  }, []);

  //Access based on credentials
  //if (token && !googleId) {
	//	return <Redirect to="/usermain" />
	//}

  return (
    <div className="container datemain-div-3">
      <Row className="mb-2">
        <Col className="datemain-div-3">
          <InputGroup className="datemain-div-3">
            <Input
              value={text}
              type="text"
              placeholder="Search for Client"
              onChange={onTextChanged}
              className="mb-1"              
            />&nbsp;&nbsp;
            <div className="datemain-div-3" style={{ height: 10 }} >
              <InputGroupAddon addonType="append">
                <Button
                  className="h-10 d-inline-block"
                  color="primary"
                  onClick={() => UpdateDisplay(text)}
                >
                  Search Client
                </Button>
              </InputGroupAddon>
            </div>            
            <div className="datemain-div-3" style={{ margin: '0px 0px 0px 20px', height: 10 }} >
              <InputGroupAddon addonType="append">
                <Button
                  className="h-10 d-inline-block"
                  color="primary"
                  onClick= {toggle}
                >
                  Add New Client
                </Button>
              </InputGroupAddon>
            </div>
          </InputGroup>
          {renderSuggestions()}
        </Col>
      </Row>

      {/* <ListGroup> */}
      <Scrollbar
        noScrollX
        style={{ height: "32vh", width: "144vh" }}
        className="col-12 col-md-12 "
      >
        <div className="datemain-div-3 row">
          {Client.map((cli) => (
            <div className="datemain-div-3 col-sm-6 mb-2" key={cli._id}>
              <div className="card" style={{ backgroundColor:"#88adce" }}>
                <div className="datemain-div-3 card-body">
                  <div className="datemain-div-3-font">
                    <h6 className="datemain-div-3">
                      Name:
                      <span className="datemain-div-3 text-uppercase"> {cli.name}</span>
                    </h6>
                  </div>                  
                  <div className="datemain-div-3">Email : {cli.email}</div>
                  <div className="datemain-div-3 row mb-0 pb-0">
                    <div className="datemain-div-3 col-md-6 ">
                      Phone Number: <span style={{color:'brown'}}>{cli.phoneNumber}</span>
                    </div>
                    
                    {selectedClient._id === cli._id && ( 
                      <div
                        className="datemain-div-3 col align-self-end col-md-2 offset-md-3 inline"
                        style={{ textAlign: "center" }}
                      >
                          <button 
                            className="btn btn-sm btn-primary"
                            onClick={() => storeClientId(cli)}
                          >                                                        
                            Unselect
                          </button>
                      </div>
                    )}

                    {selectedClient._id !== cli._id && ( 
                      <div
                        className="datemain-div-3 col align-self-end col-md-2 offset-md-3 inline"
                        style={{ textAlign: "center" }}
                      >
                          <button 
                            className="btn btn-sm btn-primary"
                            onClick={() => storeClientId(cli)}
                          >                                                        
                            Select
                          </button>                       
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Scrollbar>

      <Modal isOpen={modal} fullscreen="sm" backdrop="static" centered={true} toggle={toggle}>        
        <ModalBody className="text-left border-0">
          <Container className='text-center'>
            <Row>
              <Col lg={12} className='mt-5 '>
                <Card>
                  <Form>
                    <CardHeader className=''>Register A New Client</CardHeader>
                    <CardBody >
                      <FormGroup row>
                        <Label for='email' sm={3}>
                          Email:
                        </Label>
                        <Col sm={9}>
                          <Input
                            type='email'
                            name='email'
                            id='email'
                            placeholder='your email here'
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for='name' sm={3}>
                          FullName:
                        </Label>
                        <Col sm={9}>
                          <Input
                            name='name'
                            id='name'
                            placeholder='your name here'
                            onChange={(e) => setName(e.target.value)}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for='address' sm={3}>
                          Address:
                        </Label>
                        <Col sm={9}>
                          <Input
                            name='address'
                            id='address'
                            placeholder='your address here'                            
                            onChange={(e) => setAddress(e.target.value)}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for='phoneno' sm={3}>
                          Phone No:
                        </Label>
                        <Col sm={9}>
                          <Input
                            name='phoneno'
                            id='phoneno'
                            placeholder='your phone number'                            
                            onChange={(e) => setPhoneno(e.target.value)}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for='sex' sm={3}>
                          Sex:
                        </Label>
                        <Col sm={9}>
                          <Input
                            type="select"
                            name='sex'
                            id='sex'
                            placeholder='your sex'
                            onChange={(e) => setSex(e.target.value)}
                          >
                            <option>Male</option>
                            <option>Female</option>
                          </Input>
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for='comment' sm={3}>
                          Comment:
                        </Label>
                        <Col sm={9}>
                          <Input
                            type="textarea"
                            name='comment'
                            id='comment'
                            placeholder='your comment'
                            onChange={(e) => setComment(e.target.value)}
                          />
                        </Col>
                      </FormGroup>
                    </CardBody>
                    <CardFooter>
                      <FormGroup center style={{backgroundColor:"white"}}>
                        <Button inline-block color="primary" onClick={addClient}>
                          Add Client
                        </Button>&nbsp; &nbsp;
                        <Button inline-block color="secondary" onClick={toggle}>
                          Cancel
                        </Button>
                      </FormGroup>
                    </CardFooter>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
      
      {/* </ListGroup> */}
    </div>
  );
};

export default Search_Small;
