import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Footer from "../Basic/Footer";
import Footer2 from "../Basic/Footer2";
import Menu from "../Basic/Menu";
import Navbar from "../Basic/Navbar";
import Leftside from "../Dashboard/LeftsideClient";

const DateMain2 = (props) => { 
  const [doctors, setDoctors] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [times, setTimes] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');

  useEffect(() => {
    axios.get('http://localhost:5000/doctors/')
      .then(res => {
        setDoctors(res.data);       
      })
      .catch(err => console.log(err));
  }, []);

  const handleDoctorChange = (e) => {
    setSelectedDoctor(e.target.value);
    setSelectedDate('');
    setSelectedTime('');
    
    axios.get(`http://localhost:5000/doctors/getDoctor/doctor/${e.target.value}/startdate/${'2023-03-07'}/enddate/${'2023-03-10'}`)
      .then(res => {
        setAppointments(res.data);        
      })
      .catch(err => console.log(err));
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
    setSelectedTime('');
    
    //update the time in the times array
    times.length = 0;
    var result = appointments.find(({ date }) => date === e.target.value);
    for (var i = 0; i < result.slots.length; i++) {
        times[i] = result.slots[i].time;
    }
  };

  const handleTimeChange = (e) => {
    setSelectedTime(e.target.value);
  };

  return (
    <div style={{ height: "100vh" }}>
      <Navbar />
      <Menu />
      <div>
        <div className="row m-1" style={{ maxWidth: "100%" }}>
            {/*<div
                className="col-3 col-md-3 p-4 "
                style={{ height: "80vh" }}
            >
                <Leftside />
            </div>*/}
            <div
                className="col-9 col-md-9 p-4"
                style={{
                border: "10px solid orange ",
                height: "80vh",
                //backgroundColor: "#6c757d",
                }}
            >
                <div className="d-flex justify-content-center" style={{padding:"10px"}}>
                    <h3 style={{color:"orange"}}> Check Available Appointment Dates</h3>
                </div>
                <div>
                    <label>
                        Doctor:
                        <select value={selectedDoctor} onChange={handleDoctorChange}>
                        <option value="">--Select a doctor--</option>
                        {doctors.map(doctor => (
                            <option key={doctor._id} value={doctor._id}>{doctor.name}</option>
                        ))}
                        </select>
                    </label>
                    <br />
                    <label>
                        Date:
                        <select value={selectedDate} onChange={handleDateChange} disabled={!selectedDoctor}>
                        <option value="">--Select a date--</option>
                        {appointments.map(appt => (
                            <option key={appt._id} value={appt.date}>{appt.date}</option>
                        ))}
                        </select>
                    </label>
                    <br />
                    <label>
                        Time:
                        <select value={selectedTime} onChange={handleTimeChange} disabled={!selectedDate}>
                        <option value="">--Select a time--</option>
                        {times.map(time => (
                            <option key={time} value={time}>{time}</option>
                        ))}
                        </select>
                    </label>
                    <br />
                    {selectedDoctor && selectedDate && selectedTime &&
                        <p>You have selected an appointment with {selectedDoctor} on {selectedDate} at {selectedTime}</p>
                    }
                </div>
            </div>
        </div>
        <div>
            <Footer />
            <Footer2 />
        </div>
      </div>
    </div>
  );
}

export default DateMain2;
