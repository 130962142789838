import React, { useState, useEffect } from "react";
import Axios from "axios";
import Scrollbar from "react-scrollbars-custom";
import { BsPencilSquare } from "react-icons/bs";
import Navbar from "../Basic/Navbar";
import Menu from "../Basic/Menu";
import Footer1 from "../Basic/Footer";
import Footer2 from "../Basic/Footer2";
import "../Dashboard/dashboard.css";

import Leftside from "../Dashboard/LeftsideClient";

import { Link } from "react-router-dom";

const PatientAppointments = () => {
  const [Appointments, setAppointments] = useState([]);

  const fetchAppointments = async () => {

    const { data } = await Axios.post(
      `http://localhost:5000/clients/previous-appointments/`,
      {
        googleId: localStorage.getItem("googleId"),
      }
    );
    // console.log(data);
    setAppointments(data);
  };

  useEffect(() => {
    fetchAppointments();
  }, []);

  return (
    <div style={{ height: "100vh" }}>
      <Navbar />
      <Menu />
      <div>
        <div className="row m-5" 
         style= {{ 
          maxWidth: "100%",          
        }}>
          <div
            className="col-9 col-md-9 p-3"
            style={{
              border: "10px solid green ",
              height: "100vh",
              margin: "auto",
              padding: "5px"
              //backgroundColor: "#6c757d",
            }}
          >
            <p style={{}}>List of All Previous Appointments Ordered by Date</p>
            
            <Scrollbar
              noScrollX
              style={{ position: "", height: "90vh", width: "150vh" }}
              className="col-12 col-md-12"
            >
              <table className="table table-hover table-dark" >
                <thead>
                  <tr style={{color:"black"}}>
                    <th scope="col">Date</th>
                    <th scope="col">Time</th>
                    <th scope="col">Doctor Name</th>
                    <th scope="col">Feedback</th>
                  </tr>
                </thead>
                <tbody>
                  {Appointments.map((Appointment) => (
                    <tr key={Appointment._id} style={{color:"black"}}>
                      <th scope="row">{Appointment.date}</th>
                      <th scope="row">{Appointment.slotTime}</th>
                      <th scope="row">{Appointment.doctorName}</th>
                      <th scope="row">
                        <div style={{
                          display: 'flex'
                        }}>
                          <Link to={`/patient/feedback/${Appointment._id}`}>
                            <BsPencilSquare />
                          </Link>
                          {Appointment.feedback.given && <div style={{
                            margin: '0 15px'
                          }}>{Appointment.feedback.stars}/5</div>}
                        </div>
                      </th>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Scrollbar>
          </div>
        </div>
      </div>
      
      <Footer1 />
      <Footer2 />
    </div>    
  );
};

export default PatientAppointments;