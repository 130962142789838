import React from "react";

const About = () => {
  return (
      <div className="card my-5 about-background" >
        <div className="container" style={{ backgroundColor:"transparent"}}>
        <h1 style={{ fontFamily:"Arial Black", backgroundColor:"transparent", color:"purple", textAlign:"center" }}>
          POiNT Solutions
        </h1>
        <h3 style={{ backgroundColor:"transparent", color:"darkorange", textAlign:"center" }}>
          Client Management and Appointment System
        </h3>
        <h5 className="jumbo-user-third" style={{ backgroundColor:"transparent", textAlign:"center", fontSize:"25px" }}>
          Let's keep you organise
        </h5>
        <p className="card-text" style={{ backgroundColor:"transparent", textAlign:"justify", fontSize:"18px"}}>
          Operating your office/business/clinic efficiently is part of keeping that
          great relationship with patients/clients. That’s where Social Buzz GY 
          Client Management and Appointment System (CMAS) software comes in to play.
          This software helps your team to handle every task, including arranging 
          appointments, billing patients, communication, documentation etc… all done 
          with integrated software!
          <br/><br/>
          Any size office/business/clinic will benefit from this management software 
          to increase patient/client flow. Cost-cutting is another benefit of CMAS 
          management software. Less paper is utilized in the office, which lowers the 
          cost of printing, shipping, and office supplies. The CMAS software can bring 
          more efficiency and productivity.
        </p>
      </div>
    </div>
  );
};

export default About;


/*
One of main issue with current pandemic situation is that the
              hospitals are bit busy dealing with covid patients and other
              patients showing similar symptoms so its not easy for their
              regular patients who used to get a checkup or consultation. This
              is worsened by the restrictions due to lockdowns and the fear of
              contracting the contagious virus. So to deal with this issue we
              have come up with an approach and built this system which provides
              medical services and allows users to connect and consult with
              doctors. The aim was to build a minimalist UI for the system so
              people of any age group could easily navigate through it. A
              hospital can register their doctors in the system through their
              legal process and then patients can search doctors from varied
              specialization for consultation.
*/