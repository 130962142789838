import React from 'react'
import Menu from "../Basic/Menu"
import Navbar from "../Basic/Navbar"
import Footer from "../Basic/Footer"
import Footer2 from "../Basic/Footer2"
import ContactForm from "../Home/ContactForm"

const ContactFormUs = () => {

  return (
    <div>
      <Navbar />
      <Menu />
      <ContactForm />
      <Footer />
      <Footer2 />
    </div>
  )
}

export default ContactFormUs
