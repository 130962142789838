//import { Button } from 'react-bootstrap';
import React from 'react'; //, { useContext }
// import GoogleLogin from 'react-google-login';
import { Link } from "react-router-dom"; //, useHistory
//import { AuthContext } from '../Auth/AuthContext';
//import axios from 'axios';

const Card = ({ login, Image, link }) => {
  //const { token, googleId } = useContext(AuthContext);
  //const { token, googleId, setToken, setGoogleId } = useContext(AuthContext);
  //const history = useHistory();

  return (
    <div className="card mb-3" style={{ width: "18rem" }}>
      <div className="card-body">
        {(login === "byDoctor") && <Link to={link} className="btn btn-primary justify-content-center w-100">Appointment by Doctor</Link>}
        {(login === "byUser")   && <Link to={link} className="btn btn-primary justify-content-center w-100">Appointment by Client</Link>}        
        {(login === "byDate")   && <Link to={link} className="btn btn-primary justify-content-center w-100">Appointment by Date</Link>}        
        {(login === "byCancel") && <Link to={link} className="btn btn-danger justify-content-center w-100">Cancel an Appointment</Link>}        
      </div>          
      <img src={Image} className="card-img-top" alt="..." height="240" />
    </div>
  )
}

export default Card;

//{(login === "byDate") && <Link to={link} className="btn btn-primary justify-content-center w-100">My Dashboard</Link>}
//{(login === "byUser") && <Link to={link} className="btn btn-primary justify-content-center w-100">My Dashboard</Link>}
//{(login === "byDoctor") && <Link to={link} className="btn btn-primary justify-content-center w-100">My Dashboard</Link>}
////{((!googleId && login === "Patient") && <Button onClick={loginWithGoogle} disabled={false} className="btn btn-primary justify-content-center w-100">Login As A Patient</Button>)}