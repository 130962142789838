import React, { useContext, useState } from 'react';
import { Redirect, useHistory } from "react-router-dom";
import { Container, Row, Col, Card, Form, CardHeader, CardBody, FormGroup, CardFooter, Button, Label, Input } from 'reactstrap'
import axios from 'axios';
import { AuthContext } from '../Auth/AuthContext';

const NewUser = () => {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [status, setStatus] = useState(0);
	const { token, setToken, googleId, setGoogleId } = useContext(AuthContext);
	const history = useHistory();

	async function addUser(e) {

		try {
			const res = await axios.post("http://localhost:5000/users/add/",
				{
					username: username,
					password: password
				}
			);
			setStatus(res.status);

			const token = res.data.token;

			if (res.status === 200) {
				window.localStorage.setItem("token", token);

				// Remove the googleId if it exist in the local storage
				window.localStorage.removeItem("googleId");
				setGoogleId(null);
				setToken(token);
				history.push('/user');
			}
		} catch (err) {
			console.log(err);
		}
	}

	if (token && !googleId) {
		return <Redirect to="/usermain" />
	}
	return (
		<Container className='text-center'>
			<Row>
				<Col lg={6} className='offset-lg-3 mt-5 '>
					<Card>
						<Form>
							<CardHeader className=''>-- Register A New User --</CardHeader>
							<CardBody >
								<FormGroup row>
									<Label for='email' sm={3}>
										Email:
									</Label>
									<Col sm={9}>
										<Input
											type='email'
											name='username'
											id='username'
											placeholder='provide your username'
											onChange={(e) => setUsername(e.target.value)}
										/>
									</Col>
								</FormGroup>
								<FormGroup row>
									<Label for='password' sm={3}>
										Password:
									</Label>
									<Col sm={9}>
										<Input
											type='password'
											name='password'
											id='password'
											placeholder='provide your password'
											onChange={(e) => setUsername(e.target.value)}
										/>
									</Col>
								</FormGroup>
								<FormGroup row>
									<Label for='password' sm={3}>
										Retype Password:
									</Label>
									<Col sm={9}>
										<Input
											type='password'
											name='password'
											id='password'
											placeholder='provide your password'
											onChange={(e) => setUsername(e.target.value)}
										/>
									</Col>
								</FormGroup>
								<FormGroup row>									
								</FormGroup>
								<FormGroup row>
									<Label for='password' sm={3}>
										Name:
									</Label>
									<Col sm={9}>
										<Input
											name='name'
											id='name'
											placeholder='your name here'
											onChange={(e) => setPassword(e.target.value)}
											onKeyPress={(target) => {
												if (target.charCode === 13) {
                          							addUser();
                        					}
											} }
										/>
									</Col>
								</FormGroup>
                                <FormGroup row>
									<Label for='address' sm={3}>
										Address:
									</Label>
									<Col sm={9}>
										<Input
											name='address'
											id='address'
											placeholder='your address here'
											onChange={(e) => setPassword(e.target.value)}
											onKeyPress={(target) => {
												if (target.charCode === 13) {
                          							addUser();
                        					}
											} }
										/>
									</Col>
								</FormGroup>
                                <FormGroup row>
									<Label for='phoneno' sm={3}>
										Phone No:
									</Label>
									<Col sm={9}>
										<Input
											name='phoneno'
											id='phoneno'
											placeholder='your phone number'
											onChange={(e) => setPassword(e.target.value)}
											onKeyPress={(target) => {
												if (target.charCode === 13) {
                          							addUser();
                        					}
											} }
										/>
									</Col>
								</FormGroup>
								<FormGroup row>
									<Label for='phoneno' sm={3}>
										User Type:
									</Label>
									<Col sm={9}>
										<Input
										
											type='select'
											name='usertype'
											id='usertype'
											placeholder='select user type'
											onChange={(e) => setPassword(e.target.value)}
											onKeyPress={(target) => {
												if (target.charCode === 13) {
                          							addUser();
                        						}
											}}
										>
											<option key="" value="">--Select a UserType--</option>
											<option key="1" value="1">GodMode</option>
											<option key="2" value="2">Administrator</option>
											<option key="3" value="3">SuperUser</option>
											<option key="4" value="4">Standard</option>
											<option key="5" value="5">Guest</option>
										</Input>
									</Col>
								</FormGroup>
							</CardBody>
							<CardFooter>
                                <FormGroup center>
                                    <Button inline-block color="primary" onClick={addUser}>
                                        Add User
                                    </Button>                                    
                                    <Button inline-block color="primary" >
                                        Cancel
                                    </Button>
                                </FormGroup>
							</CardFooter>
						</Form>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default NewUser;