import React from 'react';
import Menu from "../Basic/Menu"
import Navbar from "../Basic/Navbar"
import Footer1 from "../Basic/Footer"
import Footer2 from "../Basic/Footer2"

const PopupMessage = () => {
    return (
      <div>
        <Navbar />
        <Menu />
        <div className="page-container">        
          <div className="column-page1 ">
          {/* content for the first column goes here */}
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, sapien id facilisis vulputate, justo lectus molestie odio, vel euismod eros augue in arcu. Sed hendrerit nisi at arcu pharetra, ac ultrices purus bibendum.</p>
          </div>
          <div className="column-page2 ">
          {/* content for the second column goes here */}
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, sapien id facilisis vulputate, justo lectus molestie odio, vel euismod eros augue in arcu. Sed hendrerit nisi at arcu pharetra, ac ultrices purus bibendum.</p>
          </div>
        </div>
        <Footer1 />
        <Footer2 />
      </div>
    );
}

export default PopupMessage;

 /*<Popup
 trigger={open => (
    <button className="button">Trigger - {open ? 'Opened' : 'Closed'}</button>
  )}
  position="right center"
  closeOnDocumentClick
>
  <span> Popup content </span>
  </Popup>*/