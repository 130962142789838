import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import { BrowserRouter } from "react-router-dom";

//client-id 985357798608-1g7a0g2vbiotoj96oc6qo4rbu9ki570e.apps.googleusercontent.com
//client secret - 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

ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
);
