import React from "react";
import { Link } from "react-router-dom";
import facebook from "../image/facebook.png";
import twitter from "../image/twitter.png";
import instagram from "../image/instagram.png";
import logo from "../image/userlogo.png";
import "../index.css";

 const Footer=()=> {
   
    return (
      
        <div className="col text-white p-2 footer-backgroundcolor" 
            style={{maxWidth:"100%",margin:"auto",textAlign:"center"}}>
                
            <div className="footer-backgroundcolor">
                <img className="footer-backgroundcolor" src={logo} alt="" width="120" height="100"></img>
            </div>
            <div>
                <p className="text-white footer-backgroundcolor">
                    Social Buzz GY Ltd.
                    <br/>
                    200 Guava Drive, La Platta Gardens,                    
                    <br/>
                    Valencia, Trinidad & Tobago
                    <br/>
                    +1 (868) 352-0120
                </p>                
            </div>     
            <div className="footer-backgroundcolor">
                <Link to="#!"> <img className="footer-backgroundcolor" src={facebook} alt="" width="50" height="30"></img></Link>
                <Link to="#!"> <img className="footer-backgroundcolor" src={instagram} alt="" width="50" height="30"></img></Link>
                <Link to="#!"> <img className="footer-backgroundcolor" src={twitter} alt="" width="50" height="30"></img></Link>
            </div>       
          
        </div>        
        
    );
}

export default Footer;
