import React from 'react';
import Menu from '../Basic/Menu';
import Footer from '../Basic/Footer';
import Footer2 from '../Basic/Footer2';
import Navbar from '../Basic/Navbar';
import NewUser from '../User/NewUser';


const AddUser=()=>{

    return(
        <div >
            <div style={{height: "71vh"}}>
                <Navbar />
                <Menu />
                <NewUser />
            </div>
           
            <div className="fixed-bottom" style={{width: "100%"}}>
                <Footer />
                <Footer2 />
            </div>
            
        </div>
    )

}

export default AddUser