import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import Navbar from "../Basic/Navbar";
import Leftside from "../Dashboard/LeftsideClient";
//import StripeCheckoutButton from "react-stripe-checkout";
import { toast } from "react-toastify";

function getEndDateTime(dateTime) {
  const hrs = (parseInt(dateTime.split("T")[1].split(":")[0]) + 1)
    .toString()
    .padStart(2, "0");
  const time = hrs + ":00:00";
  const date = dateTime.split("T")[0];
  return date + "T" + time;
}

const Payment = (props) => {
  const [finalBalnce, setFinalBalnce] = useState(0);
  const history = useHistory();

  function createEvent(id, dateTime, doctorEmail) {
    var virtualEvent = {
      id: id,
      summary: "Appointment",
      location: "Virtual",
      description: "Doctor-Patient appointment",
      start: {
        dateTime: dateTime,
        timeZone: "Asia/Kolkata",
      },
      end: {
        dateTime: getEndDateTime(dateTime),
        timeZone: "Asia/Kolkata",
      },
      conferenceData: {
        createRequest: {
          requestId: "7qxalsvy0e",
        },
      },
      attendees: [{ email: doctorEmail }],
      guestsCanModify: true,
      reminders: {
        useDefault: false,
        overrides: [
          { method: "email", minutes: 24 * 60 },
          { method: "popup", minutes: 15 },
        ],
      },
    };

    var request = window.gapi.client.calendar.events.insert({
      calendarId: "primary",
      resource: virtualEvent,
      sendUpdates: "all",
      supportsAttachments: true,
      conferenceDataVersion: 1,
    });

    request.execute(function (event) {
      console.log("Executed!");

      // Add meet link
      if (event) {
        // console.log(`AddEvent link : ${event.hangoutLink}, Id : ${id}`)
        Axios.put(
          `http://localhost:5000/appointments/add-meet-link`,
          {
            appointmentId: id,
            meetLink: event.hangoutLink
          }
        ).then((x) => {
          console.log(`Updated Meet Link!`);
        })
      }
    });
  }

  const { dateId, doctor, slotId } = props.location.data;

  const bookSlot = async () => {

    console.log(localStorage);
    
    const { data } = await Axios.post(
      `http://localhost:5000/doctors/book-slot/`,
      {
        googleId: localStorage.getItem("googleId"),
        patientName: JSON.parse(localStorage.getItem("user")).name,
        slotId: slotId,
        dateId: dateId,
        doctorId: doctor._id,
      }
    );

    if (data.doctorEmail) {
      createEvent(data._id, data.date + "T" + data.slotTime, data.doctorEmail);
    }
  };

  useEffect(() => {
    setFinalBalnce(1.18 * doctor.feesPerSession);
  }, [doctor.feesPerSession]);

  const makeAppointment = async (token) => {   
      bookSlot();
      //setFinalBalnce(0);
      toast("Appointment booked successfully", {
        type: "success"
      })
      history.push("/patient");

    console.log("Appointment was added");
  };

  return (
    <div style={{ height: "100vh" }}>
      <Navbar />
      <div>
        <div className="row m-5" style={{ maxWidth: "100%" }}>
          {/*<div
            className="col-3 col-md-3 p-4 "
            style={{ height: "80vh" }}
          >
            <Leftside />
          </div>*/}
          <div
            className="col-9 col-md-9 p-4 "
            style={{
              border: "10px solid gold ",
              height: "80vh",
            }}
          >
            <div className="container text-black">
              <div className="row">
                <div className="well col-xs-10 col-sm-10 col-md-6 col-xs-offset-1 col-sm-offset-1 col-md-offset-3">
                 
                  <div className="row">
                      <button
                        type="button"
                        className="btn btn-success btn-lg btn-block"
                        onClick={makeAppointment}
                      >
                        Pay Now&nbsp;&nbsp;&nbsp;
                        <span className="glyphicon glyphicon-chevron-right" />
                      </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;

/*
<StripeCheckoutButton
stripeKey="pk_test_51IabQNSCj4BydkZ3VIEbtfIJoWfSESvGSia3mSOfCYPWiyGxNxyr42IRvpmi8f8WbnhzCYBIZMyshg540TErXG3500fbHzRzLc"
token={makeAppointment}
amount={finalBalnce * 100}
name="Place Appointment"
shippingAddress
billingAddress
>
<button
  type="button"
  className="btn btn-success btn-lg btn-block"
>
  Pay Now&nbsp;&nbsp;&nbsp;
  <span className="glyphicon glyphicon-chevron-right" />
</button>
</StripeCheckoutButton>
*/