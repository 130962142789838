import React from "react";
import Menu from "../Basic/Menu"
import Navbar from "../Basic/Navbar"
import Footer1 from "../Basic/Footer"
import Footer2 from "../Basic/Footer2"
import Leftside from "../Dashboard/LeftsideDoctor";
import TodaysSchedule from "./TodaysSchedule";
import "../Dashboard/dashboard.css";
import { Button } from "reactstrap";
import Option from "../Dashboard/Option";
import { Link } from "react-router-dom";

const TodayAppointments = () => {
  return (
    <div style={{ height: "150vh", backgroundColor: "#f2f2f2"  }}>
      <Navbar />
      <Menu />
      <div className="page-container">
        <div 
          className = "column-page1 m-4"
          style = {{ height: "80vh" }}
        >                  
          <ul>
            <li>
              <Link to="/doctor">
                <Option Value="Send Client Notification" Option="today" />
              </Link>
            </li>
            <li style={{ textDecoration: "none" }}>
              <Link to="/doctor/personaldetails">
                <Option Value="Send Doctor Notification" />
              </Link>
            </li>
          </ul>
        </div>
        <div
          className="column-page2 row m-4"
          style = {{ 
            maxWidth: "100%",
            border: "10px solid brown",
            height: "80vh"
          }}>                    
          <TodaysSchedule />        
        </div>
      </div>
      <Footer1 />
      <Footer2 />
    </div>
  );
};

export default TodayAppointments;
