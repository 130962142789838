import React from "react";
//import Carousel from "react-bootstrap/Carousel";

const Jumbo=()=>{
    return(
        <div style={{height:"200"}}>
            <div
                    style={{
                    width: "100%",
                    height:"100%",
                    top: 1,
                    position: "relative",
                    padding: "20px",
                    }}
            >
                <div className="jumbo-user-second">
                        Client Management and Appointment System
                </div>
                <div className="jumbo-user-third">
                        Let's keep you organise
                </div>
            </div>
        </div>
    )
}


export default Jumbo
/*
  <div className="jumbo-user-first">
                    Social Buzz GY Ltd.
                </div>

<div className="image-container" style={{width:"70%"}}>
<img
alt = "carousel-image1"
className="d-block w-100"
src={username}
style={{ alignSelf: 'center' }}
/>              
</div>
*/