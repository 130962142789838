import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../image/userlogo.png";
import login from "../image/login2.png";
import logout from "../image/logout2.png";
import { AuthContext } from "../Auth/AuthContext";
import "../index.css";
import { Input } from "reactstrap";

const Navbar = () => {
  const { token, setToken, setGoogleId } = useContext(AuthContext);
  const history = useHistory();

  async function loginWithGoogle(e) {
      /*
      try {
          let googleuser = await window.gapi.auth2.getAuthInstance().signIn({prompt:'consent'});
          const auth2 = await window.gapi.auth2.getAuthInstance();
          if (googleuser){
              console.log("[Google] Signed in successfully!");

              var profile = auth2.currentUser.get();
              console.log(profile);

              return true;
          }
      } catch (error) {
          console.log(`[Google] Some error occurred while signing in! ${JSON.stringify(error)}`);
      }
      */    
  }

  /*
  async function loginWithGoogle(e) {
    try {
      await window.gapi.auth2.getAuthInstance().signIn();
      const auth2 = await window.gapi.auth2.getAuthInstance();
      if (auth2.isSignedIn.get()) {
        console.log("[Google] Signed in successfully!");
        var profile = auth2.currentUser.get();
        console.log(profile);
        window.localStorage.setItem("token", profile.getAuthResponse().id_token);
        window.localStorage.setItem("googleId", profile.getId());

        const serverRes = await axios.post(
          `http://localhost:5000/patients/google-login/`,
          {
            tokenId: profile.getAuthResponse().id_token,
          }
        );

        if (serverRes) {
          console.log(serverRes.data.phoneNumberExists);

          setToken(profile.getAuthResponse().id_token);
          setGoogleId(profile.getId());

          if (serverRes.data.phoneNumberExists === true) {
            history.push("/patient");
          } else {
            history.push("/patient/update-phone");
          }
        }
        else {
          const err = {err : "Server Didn't respond"}
          throw err;
        }
      }
    } catch (err) {
      console.log(`[Google] Some error occurred while signing in! ${JSON.stringify(err)}`);
    }
  }
  */

  function signOutGoogle() {
    // Different logic for doctor and patient

    // Patient logic
    /*
    if (window.gapi.auth2.getAuthInstance().isSignedIn.get()) {
      window.gapi.auth2.getAuthInstance().signOut().then(() => {
        console.log("[Google] Signed out successfully!");
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("googleId");
        setToken(null);
        setGoogleId(null);
        history.push("/");
      }).catch((err) => {
        console.log(`[Google] Some error occurred while signing out! ${err}`);
      });
    }

    // Doctor logic
    else {
      */
      localStorage.clear();
      console.log("Signed out successfully!");
      setToken(null);
      history.push("/");
    //}
  }

  return (
    <nav
      className="navbar navbar-light navbar-expand-lg pl-4 pr-4 w-100 navbar-header">
      <Link to="/" className="navbar-brand" style={{color:"white"}}>
        <img
          src={logo}
          alt=""
          width="80"
          height="60"
          className="d-inline-block align-center mr-2 mt-1 navbar-header"          
        ></img>
        Social Buzz GY Ltd.
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#collapsibleNavbar"
      >
        <span className="navbar-toggler-icon"></span>
      </button>      
      <div className="container-fluid collapse navbar-collapse navbar-header" id="collapsibleNavbar">
        <ul className="navbar-nav ml-auto text-dark">
          <li className="navbar-header" style={{ padding: 5 }}>
            <Input
              type="search"
              placeholder="Search..."  
              className="bd-search"
            >
            </Input>        
          </li>
          <li className="navbar-item navbar-header" style={{ textAlign: "right" }}>
            <link to="/" className="nav-link " style={{ padding: 0 }} />
            {!token && (
               <Link to={{ pathname: "/userlogin" }}>               
                <button             
                  type="button"
                  //onClick={loginWithGoogle}
                  className="btn"
                >
                    <img
                      src={login}
                      alt=""
                      width="35"
                      height="30"
                      className="d-inline-block align-center mr-1 mt-1 navbar-header"                      
                      title="Login"
                    />
                </button>
              </Link>
            )}
            {token && (
              <button
                type="button"
                className="btn"
                onClick={signOutGoogle}
              >
                 <img
                    src={logout}
                    alt=""
                    width="35"
                    height="30"
                    className="d-inline-block align-center mr-1 mt-1 navbar-header"                    
                    title="Logout"
                  />
              </button>
            )}
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
