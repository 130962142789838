import React from 'react'
import { Link } from "react-router-dom";
import facebook from "../image/facebook.png";
import twitter from "../image/twitter.png";
import instagram from "../image/instagram.png";
import logo from "../image/userlogo.png";

const CompanyInfo = () => {

  return (
    <div className="contact-container rounded text-white p-3 my-2 footer-backgroundcolor" 
      style={{maxWidth:"100%",margin:"auto",textAlign:"left"}}>        
      <div className="footer-backgroundcolor">
          <img className="footer-backgroundcolor" src={logo} alt="" width="120" height="100"></img>
      </div>
      <div className="column-page2 footer-backgroundcolor">          
        <div className="footer-backgroundcolor">
          <p className="h4 text-white footer-backgroundcolor">
                Social Buzz GY Ltd.
                <br/>
                200 Guava Drive, La Platta Gardens,                    
                <br/>
                Valencia, Trinidad & Tobago
                <br/>
                +1 (868) 352-0120
            </p>         
            <Link to="#!"> <img className="footer-backgroundcolor" src={facebook} alt="" width="50" height="30"></img></Link>
            <Link to="#!"> <img className="footer-backgroundcolor" src={instagram} alt="" width="50" height="30"></img></Link>
            <Link to="#!"> <img className="footer-backgroundcolor" src={twitter} alt="" width="50" height="30"></img></Link>
        </div>         
      </div>
    </div>
  );
}

export default CompanyInfo
