import React from 'react';

const Option =({Value="info",})=>{

    return(
        <button type="button" 
        
        className="btn btn-secondary p-2 m-1 w-75 h-100" style={{backgroundColor:"#1861a0"}}>
      
            <h5 className="text-white" style={{backgroundColor:"transparent"}}>{Value}</h5>
        
       </button>
    )
}

export default Option;