import React from 'react'
import axios from 'axios'

const ContactForm = () => {
  const [formStatus, setFormStatus] = React.useState('Submit')
  const [email, setEmail] = React.useState('')
  const [message, setMessage] = React.useState('')
  const [name, setName] = React.useState('')
  const [telephone, setTelephone] = React.useState('')

  const updateEmail = (e) => {
    setEmail(e.target.value);
  }

  const updateTelephone = (e) => {
    setTelephone(e.target.value);
  }

  const updateName = (e) => {
    setName(e.target.value);
  }

  const updateMessage = (e) => {
    setMessage(e.target.value);
  }

  const onSubmit = (e) => {
    e.preventDefault()
    
    setFormStatus('Sending...')
    //const { name, email, message } = e.target.elements
    let conForm = {
      name: name,
      email: email,
      message: message,
    }
    
    //send the email
    axios({
        method: "POST",
        url:"http://localhost:5000/clients/contact",
        data:  conForm
    }).then((response)=>{
        console.log(response.data.status);
        if (response.data.status === 'success') {
          alert("Message Sent.");
          this.resetForm()
        } else if(response.data.status === 'fail') {
          alert("Message failed to send.")
        }
    })

    setFormStatus("Submit");    
    setEmail('');
    setName('');
    setMessage('');
  }

  return (
    <div className="container rounded p-3 my-2 bg-light text-black" >
      <h2 className="mb-3 bg-light ">Contact Form</h2>
      <form onSubmit={onSubmit} method="POST" className="bg-light ">
        <div className="mb-3 bg-light ">
          <input className="form-control" value={name} placeholder="Name" onChange={updateName} type="text" id="name" required />
        </div>
        <div className="contact-container2 mb-3 bg-light">
          <div style={{width:"45%"}}>
            <input className="form-control" value={email} placeholder="Email" onChange={updateEmail} type="email" id="email" required />
          </div>
          <div className="bg-light" style={{width:"5%"}}></div>
          <div style={{width:"50%"}}>
            <input className="form-control" value={telephone} placeholder="Telephone"  onChange={updateTelephone} type="telephone" id="telephone" required />
          </div>
        </div>
        <div className="mb-3 bg-light ">
          <textarea className="form-control" rows="4" value={message} placeholder="Message" onChange={updateMessage} id="message" required />
        </div>
        <button className="btn btn-danger" type="submit">
          {formStatus}
        </button>
      </form>
    </div>
  )
}

export default ContactForm
