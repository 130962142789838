import React from 'react';
import Footer from '../Basic/Footer';
import Footer2 from '../Basic/Footer2';
import Navbar from '../Basic/Navbar';
import LoginForm from '../Userlogin/LoginForm';


const UserLogin=()=>{

    return(
        <div >
            <div style={{height: "71vh"}}>
            <Navbar/>
            <LoginForm/>
            </div>
           
            <div className="fixed-bottom" style={{width: "100%"}}>
            <Footer />
            <Footer2 />
            </div>
            
        </div>
    )

}

export default UserLogin