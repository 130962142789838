import React from "react";
import Footer from "../Basic/Footer";
import Footer2 from "../Basic/Footer2";
import Navbar from "../Basic/Navbar";
//import Menu from "../Basic/menu";
//import About from "../Home/About";
import Jumbo from "../Home/UserJumbo";
import AppointmentOptions from "../Home/UserButton";
//import UserOptions from "../Home/AdminButton";
import Menu from "../Basic/Menu";

const Home = () => {
  return (
    <div>
      <Navbar />
      <Menu />
      <Jumbo />
      <AppointmentOptions />
      <Footer />
      <Footer2 />
    </div>
  );
};

export default Home;
