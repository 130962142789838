import React from "react";
import Navbar from "../Basic/Navbar";
import LeftsideClient from "../Dashboard/LeftsideClient";
import Search from "./Search";
import Footer from "../Basic/Footer";
import Footer2 from "../Basic/Footer2";
import Menu from "../Basic/Menu";

const SearchClient = () => {
  return (
    <div style={{ height: "100vh" }}>
      <Navbar />
      <Menu />
      <div>
        <div className="row m-5" style={{ maxWidth: "100%" }}>
          {/*<div className="col-3 col-md-3 p-4 ">
            <LeftsideClient />
          </div>*/}
          <div
            className="col-9 col-md-9 p-4"
            style={{
              border: "10px solid lightblue ",
              height: "80vh",
              //backgroundColor: "#6c757d",
            }}
          >
            <Search />
          </div>
        </div>
      </div>
      <Footer />
      <Footer2 />
    </div>
  );
};

export default SearchClient;
