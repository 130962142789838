import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Navbar from "../Basic/Navbar";
import LeftsideUser from "../Dashboard/LeftsideClient";
import Menu from "../Basic/Menu";
import Footer from "../Basic/Footer";
import Footer2 from "../Basic/Footer2";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';

function getEndDateTime(dateTime) {
  const hrs = (parseInt(dateTime.split("T")[1].split(":")[0]) + 1)
    .toString()
    .padStart(2, "0");
  const time = hrs + ":00:00";
  const date = dateTime.split("T")[0];
  return date + "T" + time;
}

function getDateString(date) {
  let finalDate = date.getFullYear().toString()
  const month = date.getMonth() + 1
  const day = date.getDate();

  if(month < 10) {
    finalDate += ('-0' + month.toString())
  }
  else {
    finalDate += '-' + month.toString()
  }

  if(day < 10) {
    finalDate += ('-0' + day.toString())
  }
  else {
    finalDate += '-' + day.toString()
  }

  return finalDate
}

const BookingSlots = (props) => {
 
  const { date, doctor } = props.location.state;
  const [dateId, setdateId] = useState();
  const [Slots, setSlots] = useState([]);
  const history = useHistory();
  let slot_id;

  function createEvent(id, dateTime, doctorEmail) {
    var virtualEvent = {
      id: id,
      summary: "Appointment",
      location: "Virtual",
      description: "Doctor-Patient appointment",
      start: {
        dateTime: dateTime,
        timeZone: "Asia/Kolkata",
      },
      end: {
        dateTime: getEndDateTime(dateTime),
        timeZone: "Asia/Kolkata",
      },
      conferenceData: {
        createRequest: {
          requestId: "7qxalsvy0e",
        },
      },
      attendees: [{ email: doctorEmail }],
      guestsCanModify: true,
      reminders: {
        useDefault: false,
        overrides: [
          { method: "email", minutes: 24 * 60 },
          { method: "popup", minutes: 15 },
        ],
      },
    };

    var request = window.gapi.client.calendar.events.insert({
      calendarId: "primary",
      resource: virtualEvent,
      sendUpdates: "all",
      supportsAttachments: true,
      conferenceDataVersion: 1,
    });

    request.execute(function (event) {
      console.log("Executed!");

      // Add meet link
      if (event) {
        // console.log(`AddEvent link : ${event.hangoutLink}, Id : ${id}`)
        axios.put(
          `http://localhost:5000/appointments/add-meet-link`,
          {
            appointmentId: id,
            meetLink: event.hangoutLink
          }
        ).then((x) => {
          console.log(`Updated Meet Link!`);
        })
      }
    });
  }

  const bookSlot = async () => {
    console.log("bookSlot - " + slot_id);
    
    const { data } = await axios.post(
      `http://localhost:5000/doctors/book-slot/`,
      {
        userId: JSON.parse(localStorage.getItem("user")).googleId,
        clientId: JSON.parse(localStorage.getItem("client"))._id,
        slotId: slot_id,
        dateId: dateId,
        doctorId: doctor._id,
      }
    );

    if (data.doctorEmail) {
      createEvent(data._id, data.date + "T" + data.slotTime, data.doctorEmail);
    }
  };

  useEffect(() => {
    const fetchDate = async (dateToPost) => {
      const { data } = await axios.post(
        `http://localhost:5000/doctors/get-slots/`,
        {
          doctorId: doctor._id,
          date: dateToPost
        }
      );
      setdateId(data._id);
      setSlots(data.slots);
    };

    const dateToSend = getDateString(date)
    fetchDate(dateToSend);
  });

  function makeAppointment(e) {
    //check if a patient was selected
    const patientName = JSON.parse(localStorage.getItem("client")).name;
    
    if (patientName === "") {
      alert("No Client was selected");
    }
    else {
      slot_id = e.target.value;
      
      bookSlot();
      //setFinalBalnce(0);
      toast("Appointment booked successfully", {
        type: "success",
        position: 'bottom-right',
        autoClose: 1000,
      });

      alert("Appointment booked successfully");
    }
    
    history.push("/usermain");
  };

  return (
    <div style={{ height: "100vh" }}>
      <Navbar />
      <Menu />
      <div>
        <div className="row m-5" style={{ maxWidth: "100%" }}>
          {/*<div className="col-3 col-md-3 p-4">
            <LeftsideUser />
          </div>*/}
          <div
            className="col-9 col-md-9 p-4"
            style={{
              border: "10px solid purple ",
              height: "80vh",
              //backgroundColor: "#6c757d",
            }}
          >
            <div>
              <p>Appointment with Dr. {doctor.name} on {getDateString(date)} for {JSON.parse(localStorage.getItem("client")).name}</p>
            </div>
            <table className="table table-hover" style={{color:"black"}}>
              <thead>
                <tr>
                  <th scope="col">Available Slots</th>
                  <th scope="col">Booking Status</th>
                  <th scope="col">Select</th>
                </tr>
              </thead>
              <tbody>
                {Slots.map((slot) => (
                  <tr key={slot._id}>
                    <th scope="row">{slot.time}</th>
                    {slot.isBooked ? (
                      <td>Booked</td>
                    ) : (
                      <td>
                        <Link
                          to={{
                            pathname: "/user/payment",
                            data: {
                              dateId:dateId,
                              doctor:doctor,
                              slotId:slot._id,
                            },
                          }}
                        >
                          Book Now
                        </Link>
                      </td>
                    )}
                    <td>
                      <button
                        className="btn btn-success btn-lg btn-block"
                        onClick={makeAppointment}
                        value={slot._id}
                      >
                        Book Now                        
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer />
      <Footer2 />
    </div>
  );
};

export default BookingSlots;
