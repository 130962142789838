export default `addiction medicine
anaesthesia
dermatology
emergency medicine
general practice
intensive care medicine
medical administration
obstetrics and gynecology
occupational and environmental medicine
ophthalmology
pediatrics and child health
pain medicine
pathology
physician
psychiatry
public health medicine
radiation oncology
radiology
rehabilitation medicine
sexual health medicine
sport and exercise medicine
surgery`.split("\n");
