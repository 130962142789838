import React from "react";
import Navbar from "../Basic/Navbar";
import LeftsideDoctor from "../Dashboard/LeftsideDoctor";
import Search from "./Search";
import Menu from "../Basic/Menu";
import Footer from "../Basic/Footer";
import Footer2 from "../Basic/Footer2";

const SearchDoctor = () => {
  return (
    <div  style={{ height: "100vh" }}>
      <Navbar />
      <Menu />
      <div>
        <div className="row m-5" style={{ maxWidth: "100%" }}>
          {/*<div className="col-3 col-md-3 p-4  ">
            <LeftsideDoctor />
          </div>*/}
          <div
            className="col-9 col-md-9 p-4"
            style={{
              border: "10px solid green ",
              height: "80vh",
            }}
          >
            <Search />
          </div>
        </div>
      </div>
      <Footer />
      <Footer2 />
    </div>
  );
};

export default SearchDoctor;
