import React, { useContext, useState, useEffect, useMemo } from "react";
import Axios from "axios";
import Scrollbar from "react-scrollbars-custom";
import { Container, Card, Form, CardHeader, CardBody, FormGroup, CardFooter, Label } from 'reactstrap'
import { Modal,  ModalBody } from "reactstrap";
import { useHistory } from "react-router-dom";
import axios from 'axios';

import {
  Row,
  Col,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";

import Trie from "./Trie.js";
import specialization from "./specialization";
import { Link } from "react-router-dom";
import { AuthContext } from '../Auth/AuthContext';

const Search = () => {
  const [lstSpecial, setlstSpecial] = useState([]);
  const [text, setText] = useState();
  const [suggestions, setSuggestions] = useState([]);
  const [modal, setModal] = useState(false);
  const [status, setStatus] = useState();

  //const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phonenumber, setPhoneNumber] = useState();
  const [speciality, setSpeciality] = useState();
  const [feespersession, setFeepersession] = useState(0);
  
  const { token, setToken, googleId, setGoogleId } = useContext(AuthContext);
  const history = useHistory();
  
  const toggle = () => setModal(!modal);	 

  const memoized_trie = useMemo(() => {
    const trie = new Trie();

    // Insert
    for (let i = 0; i < specialization.length; i++) {
      trie.insert(specialization[i]); 
      lstSpecial.push(specialization[i]);       
    }

    return trie;
  }, [lstSpecial]);

  async function addDoctor(e) {    
		try {
			const res = await axios.post("http://localhost:5000/doctors/add/",
				{
					email: email,
	        name: name,
          password: password,
          phoneNumber: phonenumber,
          specialization: speciality,
          feesPerSession: feespersession
				}
			);
			setStatus(res.status);
      
      /*
			const token = res.data.token;
			if (res.status === 200) {
				window.localStorage.setItem("token", token);

				//Remove the googleId if it exist in the local storage
				//window.localStorage.removeItem("googleId");
				setGoogleId(null);
				setToken(token);
				history.push('/usermain');
			}
      */
		} catch (err) {
			console.log(err);
		}
	}

  function storeDoctorID(id) {
    localStorage.setItem("doctor",id);

    console.log(localStorage);
  }

  function onTextChanged(e) {
    let value = e.target.value;
    setText(value);
    fetchDoctor();
    value = value.toLowerCase();
    if (value !== "") setSuggestions(memoized_trie.find(value));
    else setSuggestions([]);
  }

  function suggestionSelected(value) {
    setText(value);
    setSuggestions([]);
  }

  function renderSuggestions() {
    if (suggestions.length === 0) {
      return null;
    }
    return (
      <InputGroup>
        <ul className="list-group dropdown-menu pt-0 pb-0">
          {suggestions.map((item) => (
            <li
              className="list-group-item list-group-item-action"
              onClick={() => suggestionSelected(item)}
              key={item}
            >
              {item} 
            </li>
          ))}
        </ul>
      </InputGroup>
    );
  }

  const [Doctor, setDoctor] = useState([]);

  const fetchDoctor = async () => {
    const { data } = await Axios.get(
      `http://localhost:5000/doctors/`
    );
    setDoctor(data);
    console.log(data);
  };

  const UpdateDisplay = (text) => {
    setDoctor((Doctor) => {
      return Doctor.filter(
        //(doctor) => doctor.name.toLowerCase() === text.name()
        (doctor) => doctor.name.toLowerCase().includes(text.toLowerCase())
      );
    });
    console.log(Doctor);
  };

  useEffect(() => {
    fetchDoctor();
  }, []);

  return (
    <div>
      <Row className="mb-3">
        <Col>
          <InputGroup>
            <Input
              value={text}
              type="text"
              placeholder="Search Your Doctor"
              onChange={onTextChanged}
              className="mb-1"
            />&nbsp;&nbsp;
            <div style={{ height: 10 }} className="">
              <InputGroupAddon addonType="append">
                <Button
                  className="h-10 d-inline-block"
                  color="primary"
                  onClick={() => UpdateDisplay(text)}
                >
                  Search Doctor
                </Button>
              </InputGroupAddon>              
            </div>
            <div style={{ margin: '0px 0px 0px 20px', height: 10 }} >
              <InputGroupAddon addonType="append">
                <Button
                  className="h-10 d-inline-block"
                  color="danger"
                  onClick= {toggle}
                >
                  New Doctor
                </Button>
              </InputGroupAddon>
            </div>
          </InputGroup>
          {renderSuggestions()}
        </Col>
      </Row>

      {/* <ListGroup> */}
      <Scrollbar
        noScrollX
        style={{ position: "", height: "64vh", width: "144vh" }}
        className="col-12 col-md-12"
      >
        <div className="row">
          {Doctor.map((doc) => (
            // <ListGroupItem key={doc.id} className="mb-3">
            <div className="col-sm-6 mb-2" key={doc._id}>
              <div className="card">
                <div className="card-body">
                  <div className="text-info">
                    <h6>
                      Doctor Name:
                      <span className="text-uppercase"> {doc.name}</span>
                    </h6>
                  </div>
                  <div>Specialization : {doc.specialization}</div>
                  <div>Phone Number : {doc.phoneNumber}</div>
                  <div className="row mb-0 pb-0">
                    <div className="col-md-6 ">
                      FeesPerSession: {doc.feesPerSession}
                    </div>
                    <div
                      className=" col align-self-end col-md-2 offset-md-3 inline"
                      style={{ textAlign: "center" }}
                    >
                      <Link to={{ pathname: "/doctor/selectdate", doctor: { doctor: doc } }}>
                        <button 
                          className="btn btn-sm btn-primary"
                          onClick={() => storeDoctorID(doc._id)}
                        >  
                          Book
                        </button> 
                      </Link>
                    </div>
                  </div>

                  {/* </ListGroupItem> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </Scrollbar>

      <Modal isOpen={modal} fullscreen="sm" backdrop="static" centered="true" toggle={toggle}>        
        <ModalBody className="text-left border-0">
          <Container className='text-center'>
            <Row>
              <Col lg={15} className='mt-10 '>
                <Card>
                  <Form>
                    <CardHeader className=''>Register A New Doctor</CardHeader>
                    <CardBody >
                      <FormGroup row>
                        <Label for='email' sm={3}>
                          Email:
                        </Label>
                        <Col sm={9}>
                          <Input
                            type='email'
                            name='email'
                            id='email'
                            placeholder='your email here'
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for='password' sm={3}>
                          Password:
                        </Label>
                        <Col sm={9}>
                          <Input
                            type='password'
                            name='password'
                            id='password'                            
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for='name' sm={3}>
                          Fullname:
                        </Label>
                        <Col sm={9}>
                          <Input
                            name='name'
                            id='name'
                            placeholder='your name here'                            
                            onChange={(e) => setName(e.target.value)}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for='phonenumber' sm={3}>
                          Phone No:
                        </Label>
                        <Col sm={9}>
                          <Input
                            name='phonenumber'
                            id='phonenumber'
                            placeholder='your phone number'                            
                            onChange={(e) => setPhoneNumber(e.target.value)}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for='speciality' sm={3}>
                          Specialization:
                        </Label>
                        <Col sm={9}>
                          <Input
                            type="select"
                            name='speciality'
                            id='speciality'
                            placeholder='your sex'
                            onChange={(e) => setSpeciality(e.target.value)}
                          > 
                            { lstSpecial.map(item => {
                              return (<option key={item} value={item}>{item}</option>);
                            })}
                          </Input>
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for='doctorfee' sm={3}>
                          Doctor's Fee:
                        </Label>
                        <Col sm={9}>
                          <Input
                            type="number"
                            name='doctorfee'
                            id='doctorfee'
                            placeholder='doctors fee'
                            onChange={(e) => setFeepersession(e.target.value)}
                          />
                        </Col>
                      </FormGroup>
                    </CardBody>
                    <CardFooter>
                      <FormGroup center style={{backgroundColor:"white"}}>
                        <Button inline-block color="primary" onClick={addDoctor}>
                          Add Doctor
                        </Button>&nbsp; &nbsp;
                        <Button inline-block color="secondary" onClick={toggle}>
                          Cancel
                        </Button>
                      </FormGroup>
                    </CardFooter>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
      
      {/* </ListGroup> */}
    </div>
  );
};

export default Search;
