import React from "react";
import Image from "../image/doctor.jpg";
import Menu from "../Basic/Menu"
import Navbar from "../Basic/Navbar"
import Footer from "../Basic/Footer"
import Footer2 from "../Basic/Footer2"

const About = () => {
  return (    
    <div>
      <Navbar />
      <Menu />
    <div className="container">
    
    <div className="card my-5  ">
      <div className="row g-0">
        <div className="col-md-4 order-md-2">
          <img src={Image} className="img-fluid rounded-start" alt="..." 
            width={300}
            height={300}
          />
        </div>
        <div className="col-md-8">
          <div className="card-body">
            <h5 className="card-title" style={{color:"darkorange", textAlign:"center"}}>Client Management and Appointment System</h5>
            <p className="card-text" style={{textAlign:"center"}}>
              Operating your office/business/clinic efficiently is part of keeping that
              great relationship with patients/clients. That’s where Social Buzz GY 
              Client Management and Appointment System (CMAS) software comes in to play.
              This software helps your team to handle every task, including arranging 
              appointments, billing patients, communication, documentation etc… all done 
              with integrated software!
              <br/><br/>
              Any size office/business/clinic will benefit from this management software 
              to increase patient/client flow. Cost-cutting is another benefit of CMAS 
              management software. Less paper is utilized in the office, which lowers the 
              cost of printing, shipping, and office supplies. The CMAS software can bring 
              more efficiency and productivity.
            </p>
          </div>
        </div>
      </div>
    </div>
    </div>
    <Footer />
    <Footer2 />
    </div>
  );
};

export default About;
