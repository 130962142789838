import React, { useState, useEffect } from "react";
import Axios from "axios";
import jwt_decode from "jwt-decode";
import Scrollbar from "react-scrollbars-custom";
import Navbar from "../Basic/Navbar";
import "../Dashboard/dashboard.css";
import StarPicker from 'react-star-picker';
import Leftside from "../Dashboard/LeftsideDoctor";
import { Link } from "react-router-dom";
import Menu from "../Basic/Menu";
import Footer1 from "../Basic/Footer";
import Footer2 from "../Basic/Footer2";
import Option from "../Dashboard/Option";

const DocAppointments = () => {

  //   console.log(decoded);

  const [Appointments, setAppointments] = useState([]);

  const fetchAppointments = async () => {

    var token = localStorage.getItem("token");
    var decoded = jwt_decode(token);
    const { data } = await Axios.post(
      `http://localhost:5000/doctors/previous-appointments/`,
      {
        doctorId: decoded._id,
      }
    );
    // console.log(data);
    setAppointments(data);
  };

  useEffect(() => {
    fetchAppointments();
  }, []);

  return (
    <div style={{ height: "100vh" }}>
      <Navbar />
      <Menu />
      <div>
        <div className=" page-container row m-5" style={{ maxWidth: "100%" }}>
          <div
            className="column-page1 col-3 col-md-3 p-4 "
            style={{ height: "80vh" }}
          >
          <ul>
            <li>
              <Link to="/doctor">
                <Option Value="Email to Doctor" Option="today" />
              </Link>
            </li>
          </ul>
          </div>
          <div
            className="column-page2 col-9 col-md-9 p-3"
            style={{
              border: "15px solid brown ",
              height: "80vh",
              //backgroundColor: "#6c757d",
            }}
          >
            <p>List of Appointments by Ordered Date</p>
            
            <Scrollbar
              noScrollX
              style={{ position: "", height: "73vh", width: "150vh" }}
              className="col-12 col-md-12"
            >
              <table className="table table-hover table-dark" style={{color:"black"}}>
                <thead>
                  <tr id="#header">
                    <th scope="col">Date</th>
                    <th scope="col">Time</th>
                    <th scope="col">Patient Name</th>
					          <th scope="col" style={{textAlign:'center'}}>Feedback</th>
                  </tr>
                </thead>
                <tbody>
                  {Appointments.map((Appointment) => (
                    <tr id="#row" key={Appointment._id}>
                      <th scope="row">{Appointment.date}</th>
                      <th scope="row">{Appointment.slotTime}</th>
                      <th scope="row">{Appointment.patientName}</th>
					  {Appointment.feedback.given ? <th scope="row" style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
						  <StarPicker value={Appointment.feedback.stars} size="20"></StarPicker>
						  <Link to={`/doctor/feedback/${Appointment._id}`}>Details</Link>
					  </th> : <th scope="row" style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>-</th>}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Scrollbar>
          </div>
        </div>
      </div>
      <Footer1 />
      <Footer2 />
    </div>
  );
};

export default DocAppointments;
