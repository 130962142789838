import React from "react";
import "../index.css";

const Footer2=()=> {
   
    return (
      
        <div className="col text-center text-black p-2 footer1-backgroundcolor">         
            <p className="footer1-backgroundcolor" > info@socialbuzzgy.com
            <br />
            Copyright 2023 by IONAfuture. All Rights Reserved.</p>
        </div>        
    );
}

export default Footer2;
