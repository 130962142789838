import React from "react";
//import Carousel from "react-bootstrap/Carousel";
import vd1 from "../video/mainpage-video.mp4"
// import co5 from "../image/co5.jpg"

const Jumbovideo = () => {
    return(
      <div>
        <video
          width= "100%"
          height="400px"
          autoPlay
          loop
          muted
        >
          <source src={vd1} type="video/mp4"/>
        </video>                                  
      </div>
    )
}


export default Jumbovideo