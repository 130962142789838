import React from "react"; //, { useContext }
import Navbar from "../Basic/Navbar";
import Footer from "../Basic/Footer";
import Footer2 from "../Basic/Footer2";
import Leftside from "../Dashboard/LeftsideClient";
import { useState, useEffect } from "react";
import Axios from "axios";
import "../Dashboard/dashboard.css";
import jwt_decode from "jwt-decode";
//import { AuthContext } from "../Auth/AuthContext";

const UserDashboard = () => {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  //const { googleId } = useContext(AuthContext);

  //get the login in user info //2023/03/08
  const token = localStorage.getItem("token");  
  var decoded = jwt_decode(token);
  const googleId = decoded.googleId;

  useEffect(() => {
    setLoading(true);
    const getUserDetails = async () => {
      const res = await Axios.get(
        `http://localhost:5000/users/getUserDetails/${googleId}`
      );
      if (res.status === 200) {
        console.log(res);

        setUser(res.data);
        window.localStorage.setItem("user", JSON.stringify(res.data));
        setLoading(false);
      } else {
        console.log(res.data.message);
        setLoading(false);
      }
    };
    getUserDetails();
  }, [googleId]);

  return (
    <div style={{ height: "100vh", backgroundColor: "#f2f2f2" }}>
      <Navbar />
      {loading ? (
        <div className="row justify-content-center position-relative">
          <div
            className="spinner-border align-middle d-flex justify-content-center position-absolute top-50 start-50 translate-middle"
            style={{ width: "10rem", height: "10rem" }}
            role="status"
          ></div>
        </div>
      ) : (
        <div>
          <div className="row m-5" style={{ maxWidth: "100%" }}>
            {/*<div
              className="col-3 col-md-3 p-4"
              style={{ height: "80vh", backgroundColor: "#f2f2f2" }}
            >
              <Leftside />
            </div>*/}
            <div
              className="col-9 col-md-9 p-4"
              style={{
                border: "10px solid #419a1d",
                height: "80vh",
                backgroundColor: "#f2f2f2",
              }}
            >
              <div className="row ">
                <div className="col-9 col-md-9 p-4">
                  <div className="card mb-4">
                    <h4 className="card-header">Personal Details</h4>
                    <ul className="list-group">
                      <li className="list-group-item">
                        <span className="badge badge-success mr-2 p-2">
                          Name:
                        </span>
                        {user.name}
                      </li>
                      <li className="list-group-item">
                        <span className="badge badge-success mr-2 p-2">
                          Email:
                        </span>
                        {user.googleId}
                      </li>
                      <li className="list-group-item">
                        <span className="badge badge-success mr-2 p-2">
                          Phone No:
                        </span>
                        {user.phoneNumber}
                      </li>
                    </ul>
                  </div>
                </div>                
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
      <Footer2 />
    </div>
  );
};

export default UserDashboard;
