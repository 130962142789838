import React, { useState, useEffect } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import moment from 'moment';

const TodaysSchedule = () => {
  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
    const fetchAppointments = async () => {
      var token = localStorage.getItem("token");
      var decoded = jwt_decode(token);
      const { data } = await axios.post(
        `http://localhost:5000/doctors/todays-appointments-alldoctors`,
        {
          doctorId: decoded._id,
        }
      );

      setAppointments(data);
      console.log(data);
    };

    fetchAppointments();
  }, []);

  return (
    <div>
      <p className="center" style={{color:"darkyellow"}}>
        List of Appointments for {moment().format('Do MMMM YYYY')}
      </p>
      <table className="table table-hover table-dark" style={{ color:"black", maxWidth: "100%" }}>
        <thead>
          <tr>
            <th scope="col">Date</th>
            <th scope="col">Time</th>
            <th scope="col">Patient Name</th>
            <th scope="col">Meet Link</th>
          </tr>
        </thead>
        <tbody>
          {appointments.map((appointment) => (
            <tr key={appointment._id}>
              <th scope="row">{appointment.date}</th>
              <th scope="row">{appointment.slotTime}</th>
              <th scope="row">{appointment.patientName}</th>
              <th scope="row"><a href={appointment.googleMeetLink} target="_blank" rel="noopener noreferrer">Join Meet</a></th>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TodaysSchedule;
