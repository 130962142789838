import React from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../index.css";

const Navbar = () => {
  
  return (
    <nav className="navbar navbar-light navbar-expand-lg ">     
      <span class="navbar-brand mb-0 h1"></span>      
    </nav>
  );
}

export default Navbar;
