import React, { useState, useEffect } from "react";
import Navbar from "../Basic/Navbar";
import SearchClient from "../Client/Search_Small";
//import BookingSlots from "../Doctor/BookingSlots";
//import Leftside from "../Dashboard/LeftsideClient";
//import "react-calendar/dist/Calendar.css";
import { Link } from "react-router-dom";
//import Calendar from "react-calendar";

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
//import { DateRangePicker } from 'react-date-range';
//import { addDays } from 'date-fns';
//import { theme } from 'react-trip-date'

import Footer from "../Basic/Footer";
import Footer2 from "../Basic/Footer2";
import Menu from "../Basic/Menu";
import moment from 'moment';
import axios from 'axios';

import {
    Row,
    Input,
    Button,
  } from "reactstrap";


const DateMain = (props) => {  
  const [startdate, setStartDate] = React.useState(moment().format('YYYY-MM-DD'));
  const [enddate, setEndDate] = React.useState(moment().format('YYYY-MM-DD'));

  const [doctors, setDoctors] = useState([]);    
  const [appointments, setAppointments] = useState([]);
  const [times, setTimes] = useState([]);  

  const [selectedDoctor, setSelectedDoctor] = useState('');
  const [selectedDoctorInfo, setSelectedDoctorInfo] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  //const [selectedClient, setSelectedClient] = useState(''); //
  //const [selectedClientInfo, setSelectedClientInfo] = useState('');
  const [showAvailable, setShowAvailable] = useState(false);

  function makeAppointment(e) {
    //check if a client was selected
    if (localStorage.getItem("client") !== '') {
      alert("client not empty");
      //<BookingSlots />
    }
    else {
      alert("No Client was Selected for the Appointment");
    }
  }

  function onStartDateChange(e) {
    let value = e.target.value;
    setStartDate(value);
  };

  function onEndDateChange(e) {
    let value = e.target.value;
    setEndDate(value);
  };

  function handleDoctorChange(e) {    
    let value = e.target.value;
    setSelectedDoctor(value);

    setSelectedDate('');
    setSelectedTime('');

    //get the appointments for the doctor for a specific period
    axios.get(`http://localhost:5000/doctors/getDoctor/doctor/${value}/startdate/${startdate}/enddate/${enddate}`)
    .then(res => {
        setAppointments(res.data);        
    })
    .catch(err => console.log(err));

    //get selected doctor's informaton
    axios.post(
      `http://localhost:5000/doctors/getOneDoctor`,
      {
        doctorId: value,
      }
    ).then((res) => {
        setSelectedDoctorInfo(res.data);        
    })
    .catch(err => console.log(err));
    
    //get selected client's informaton
    /*
    axios.post(
      `http://localhost:5000/clients/getClientbyId`,
      {
        clientId: selectedClient,
      }
    ).then((res) => {
        setSelectedClientInfo(res.data);        
    })
    .catch(err => console.log(err));
    */

    //empty the current client from the localStorage
    localStorage.setItem("client","");
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
    setSelectedTime('');
       
    //update the time in the times array
    times.length = 0;
    var result = appointments.find(({ date }) => date === e.target.value);
    for (var i = 0; i < result.slots.length; i++) {
        times[i] = result.slots[i].time;
    }
  };

  const handleTimeChange = (e) => {
    setSelectedTime(e.target.value);
  };

  const renderSuggestions = (e) => {    
    axios.get(`http://localhost:5000/doctors/`,)
    .then(res => {
      setShowAvailable(true);
      setDoctors(res.data);
    })
    .catch(err => console.log(err));    
  };
  
  useEffect(() => {
    axios.get('http://localhost:5000/doctors/')
      .then(res => {
        setDoctors(res.data);       
      })
      .catch(err => console.log(err));
  },[]);

  return (
    <div style={{ height: "100vh" }}>
      <Navbar />
      <Menu />
      <div className="page-container">
        <div className="column-page1 ">
           {/*<div
            className="col-3 col-md-3 p-4 "
            style={{ height: "80vh" }}
          >
            <Leftside />
          </div>*/}
        </div>
        <div className=" column-page2 row m-1" style={{ maxWidth: "100%" }}>
          <div
            className="col-9 col-md-12 p-4"
            style={{
              border: "10px solid brown",
              height: "200vh",              
            }}
          >
            <div className="d-flex justify-content-center" style={{padding:"7px"}}>
                <h3 style={{color:"orange"}}> Check Available Appointment Dates</h3>
            </div>
            <Row>
              <p className="datemain-label-heading1">Choose the Date Filter</p>
            </Row>
            <Row className="mb-2" style={{width:'100%'}}>
                <div className="container rounded datemain-div-1 p-3">
                  <table style={{width:'72%'}}>
                      <thead>
                        <tr>
                            <th className="datemain-div-1">Start Date</th>
                            <th className="datemain-div-1"></th>
                            <th className="datemain-div-1">End Date</th>
                            <th className="datemain-div-1"></th>
                            <th className="datemain-div-1"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                            <td className="datemain-div-1">
                                <Input
                                    type="date"
                                    name='appdate'
                                    id='appdate'                  
                                    onChange={onStartDateChange}
                                    width='70'
                                    value={startdate}
                                >                  
                                </Input>
                            </td>
                            <td className="datemain-div-1">&nbsp;</td>
                            <td className="datemain-div-1">
                                <Input
                                    type="date"
                                    name='appdate'
                                    id='appdate'                  
                                    onChange={onEndDateChange}
                                    width='70'
                                    value={enddate}
                                  >                  
                                </Input>
                            </td>
                            <td className="datemain-div-1">&nbsp;</td>
                            <td className="datemain-div-1">
                                <Button
                                    className="h-10 d-inline-block"
                                    color="primary"
                                    onClick={renderSuggestions}
                                    >
                                    Check Available Dates
                                </Button>
                            </td>
                        </tr>
                      </tbody>
                  </table>
                </div>
            </Row>            
            <Row className="mb-2" style={{width:'100%'}}>
              { showAvailable &&
                <div className="container rounded border border-info datemain-div-2 p-3">     
                  <p className="datemain-label-heading2">Available Doctors, Dates and Times</p>
                  <table style={{width:'100%'}}>
                      <thead>
                        <tr>
                            <th className="datemain-div-2">Doctors</th>
                            <th className="datemain-div-2"></th>
                            <th className="datemain-div-2">Dates</th>
                            <th className="datemain-div-2"></th>
                            <th className="datemain-div-2">Times</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="datemain-div-2">
                            <Input
                                  type="select"
                                  name='_doctorName'
                                  id='_doctorName'
                                  onChange={handleDoctorChange}
                                  //size="15"
                                  value= {selectedDoctor}
                              >
                              <option value="">--Select a doctor--</option>
                              {doctors.map(doctor => (
                                  <option key={doctor._id} value={doctor._id}>{doctor.name}</option>
                              ))}
                              </Input>                   
                          </td>
                          <td className="datemain-div-2">&nbsp;</td>
                          <td className="datemain-div-2">       
                            <Input
                                  type="select"
                                  name='_doctorDate'
                                  id='_doctorDate'
                                  onChange={handleDateChange}
                                  //size="15"
                                  value= {selectedDate}
                                  disabled={!selectedDoctor}
                              >
                                <option value="">--Select a date--</option>
                                  {appointments.map(appt => (
                                      <option key={appt._id} value={appt.date}>{appt.date}</option>
                                  ))}
                              </Input>      
                          </td>
                          <td className="datemain-div-2">&nbsp;</td>
                          <td className="datemain-div-2">
                              <Input
                                  type="select"
                                  name='_doctorTime'
                                  id='_doctorTime'
                                  onChange={handleTimeChange}
                                  //size="15"
                                  value= {selectedTime}
                                  disabled={!selectedDate}
                              >
                                <option value="">--Select a time--</option>
                                {times.map(time => (
                                    <option key={time} value={time}>{time}</option>
                                ))}
                              </Input>                       
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                }
            </Row>            
            <Row style={{width:'100%'}}>
              {selectedDoctor && selectedDate && selectedTime &&
                <div className="container rounded border border-info datemain-div-3 p-3">    
                  <p className="datemain-label-heading2">Choose Client for the Appointment</p>
                  <SearchClient />
                </div>
              }
            </Row>
            <Row>
              {selectedDoctor && selectedDate && selectedTime &&            
                //<Link to={{ pathname: "/client/searchclient"}}>
                <div className="container p-3">
                  <Link
                    to={{
                      pathname: "/client/book-slot",
                      state: {
                        date: new Date(selectedDate+"T"+selectedTime),
                        time: selectedTime, 
                        doctor: selectedDoctorInfo,
                        isdate: true,
                      },
                    }}
                  >
                    <Button 
                      className="btn btn-md btn-danger" 
                      //onClick={() => makeAppointment()}
                    >
                        Create the Appointment
                    </Button>
                  </Link>
                </div>
              }
            </Row>
            <Row>
              <div>

              </div>              
            </Row>
            <br />
          
            {/*<Row className="mb-2" style={{width:'100%'}}>
              <div className="container rounded bg-white p-3">
                <DateRangePicker
                  onChange={item => setState([item.selection])}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={state}
                  direction="horizontal"  
                  minDate={addDays(new Date(), 1)}
                  //maxDate={addDays(new Date(), 30)}                  
                />
              </div>
            </Row>*/}
          </div>
        </div>
      </div>
      <div>
          <Footer />
          <Footer2 />
        </div>
    </div>
  );
};

export default DateMain;